import React from "react";
import ReactTooltip from "react-tooltip";

const Tooltip = ({ children, text, position = "right", type = "dark" }) => {
  return (
    <>
      <span data-tip={text}>{children}</span>
      <ReactTooltip place={position} effect="solid" type={type} />
    </>
  );
};

export default Tooltip;
